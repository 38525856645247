@import "node_modules/bootstrap/scss/bootstrap"; //px to em conversion
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
/* ==========================================================================
992px to 1230px
========================================================================== */
@include media-breakpoint-down(xl) {
  #homepageThreeColumn {
    .bucketCaption {
      p {
        max-width: 250px!important;
      }
    }
  }
}
/* ==========================================================================
768px to 992px
========================================================================== */
@include media-breakpoint-down(lg) {
  header {
    ul {
      font-size: em(15);
    }
  }

  #mainNav {
    ul {
      .wideFormatNav {
        .mainNavCallout {
          float: left;
        }
      }
    }
  }

  .homepageSlideshowContainer {
    height: 465px;
  }

  #homeTabs {
    .nav-tabs {
      .nav-item {
        &:nth-child(2),
        &:nth-child(3) {
          padding: 17px 0;
        }

        .nav-link {
          &.active {
            margin-bottom: 7px;
          }
        }
      }
    }
  }

  #paper-tab-panel {
    .tabPaperColor {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }

    .tabPaperSize {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }

    .tabPaperType {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }
  }

  #envelopes-tab-panel {
    .tabEnvColor {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }

    .tabEnvSize {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }
  }

  #wide-tab-panel {
    .tabWideWidth {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }

    .tabEnvSize {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }
  }

  #homepageTwoColumn {
    div.d-md-flex {
      div:nth-child(1) {
        padding-right: 1.7%;
        width: 48.3%;
      }

      div:nth-child(2) {
        padding-left: 1.7%;
        width: 48.3%;
      }
    }

    h2 {
      font-size: 1.2em;
    }
  }

  #homepageThreeColumn {
    img {
      max-width: 100%;
      height: auto;
    }

    div.d-md-flex {
      div {
        width: 30%;
      }

      div:nth-child(1) {
        padding:0;
      }

      div:nth-child(2) {
        padding:0;
      }

      div:nth-child(3) {
        padding:0;
      }

      .bucketCaption {
        width: 100%;
        height:220px;
        padding: 0 16%!important;
        font-size: 0.9em;

        p {
          max-width: 300px;
        }
      }
    }

    h2 {
      font-size: 1.2em;
    }
  }

  #footer {
    .footerColumn {
      &:nth-child(4) {
        text-align: center;
      }

      .socialMedia {
        width: 80%;
        display: inline-block;

        li {
          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }

      .copyright {
        p {
          font-size: 1em;
        }
      }

      .footerCallout {
        margin-top: 85px;

        h5 {
          font-size: 1.4em;
        }
      }
    }
  }

  .accordion {
    h5 {
      font-size: 1.2em;
    }

    .twoColumnImageAccordion {
      img {
        margin-top: 3.3em;
      }
    }
  }

  #mainPageContent {
    .widgetRow {
      .blogWidget {
        .blogBevel {
          .blogArticle {
            .thumb {
              width: 27%;
            }

            .teaser {
              width: 70%;
            }
          }
        }
      }
    }

    .instagramWidget {
      .instagramContainer {
        height: 246px!important;
      }
    }
  }

  #mainListingContent {
    #productResults {
      .resultsPaneSort {
        .breadcrumbs {
          width: 100%;
          display: block;
        }

        .itemsPerPage,
        .itemsSortBy {
          float: left;
        }

        .itemsSortBy {
          margin-left: 6%;
        }

        #itemsSortBy {
          right: unset;
          margin-left: 60px;
        }
      }

      h2 > span:after,
      h2 > span:before {
        margin: 0 20px;
      }
    }

    .filterContainer {
      width: 33%;
      padding-right: 3.41%;
    }

    #productResults {
      width: 67%;
      padding-left: 3.41%;
    }
  }

  #freeShipCallout {
    .freeShipMessage {
      background: url("../assets/imgs/freeshipBg_930px.jpg") no-repeat;
    }
  }
  /* Product Details Page */
  #mainDetailContent {
    #productInfo {
      .productImages {
        width: 43.97%;

        .mainProductImage {
          img {
            width: 100%;
            height: auto;
          }
        }

        .slideViewContainer {
          width: 280px;
        }
      }

      #productCartSection {
        h2 {
          font-size: 1.9em;
        }

        .priceDetails {
          .mainPrice {
            padding: 8px 10% 8px 0;

            p {
              &:first-child {
                font-size: 1.9em;
              }

              &:last-child {
                font-size: 1.3em;
                margin-bottom: 0;
              }
            }
          }
        }

        .volumePrice {
          p {
            &:first-child {
              font-size: 1.2em;
            }
          }

          table {
            font-size: 1.1em;
          }
        }

        .customizePaperBtn {
          margin-top: 30px;

          p {
            font-size: 1rem;
          }

          .circle {
            height: 46px;
            font-size: 2.1rem;

            i {
              margin-top: 7px;
            }
          }
        }

        .sampleSearchBtns {
          margin-top: 40px;

          a {
            font-size: 0.9em;
          }
        }

        .paperFinderBtn {
          font-size: 0.93rem;

          p {
            margin-right: 6px;
          }

          span {
            height: 21px;
            width: 21px;

            i {
              font-size: 0.8rem;
              margin: 5px 0 0 3px;
            }
          }
        }

        .addToCart {
          button {
            font-size: 1.2em;
            height: 52px;
            width: 67%;
          }

          input[type="text"] {
            width: 27.42%;
          }
        }
      }
    }

    .relatedProducts {
      .tns-outer {
        width: 792px;
      }

      .allRelatedProducts {
        width: 792px;
      }
    }

    #productQuickLinks {
      ul {
        a {
          font-size: 0.8em;
        }
      }
    }

    #productDetails {
      .nav-tabs {
        .nav-link {
          font-size: 1rem;
        }
      }
    }

    #colorOptions-tab-panel {
      a {
        margin-bottom: 35px;
      }
    }
  }

  #cuttingModal {
    .modal-dialog {
      max-width: 930px;

      .modal-content {
        .modal-body {
          padding: 4%;
        }

        .nav-tabs {
          li {
            &.nav-item {
              width: 30%;
            }
          }
        }

        .tab-content {
          .tab-pane {
            padding: 3%;

            .customizationOptionsWrapper {
              .cuttingOptions {
                width: 52%;
                .standardFormatBtn {
                  padding: 10px 8%;
                }
              }
            }

            .customizationOptionsWriteWrapper {
              .customCutTextarea {
                width: 52%;
              }
            }
          }
        }
      }
    }
  }
  .cutLineIndicator,
  .perfLineIndicator,
  .scoreLineIndicator{
    padding-right:10px;
  }
}
/* ==========================================================================
576px to 768px
========================================================================== */
@include media-breakpoint-down(md) {
  #preheader {
    font-size: em(15);
  }
  /* ---Header Edits--- */
  header {
    margin-top: 2.9em;

    .logo {
      margin-top: 3.7em;
    }

    .search {
      form {
        input[type=text] {
          width: 215px;
        }
      }
    }

    ul {
      text-align: right;

      li {
        margin-left: 0;

        a {
          font-size: em(15);
        }
      }
    }
  }
  /* ---End Header--- */
  /* ---Nav Edits--- */
  #mainNav {
    ul {
      font-size: em(15);

      .dropdownContainer {
        p {
          font-size: 0.9rem;
        }

        .dropdownColumn {
          margin-bottom: 2.5em;
        }

        .dropdownEnvBrand,
        .dropdownEnvSize,
        .dropdownPaperBrand,
        .dropdownPaperColor,
        .dropdownPaperSize,
        .dropdownPaperType,
        .dropdownPaperWeight,
        .dropdownRollWidth,
        .dropdownWideType {
          width: 47.06%;
        }

        .dropdownEnvColor {
          width: 97.2%;

          ul {
            columns: 4;
            -webkit-columns: 4;
            -moz-columns: 4;
          }
        }

        .dropdownPaperWeight {
          clear: left;
        }

        .dropdownWidePrinterInk {
          width: 97.2%;

          ul {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
          }
        }

        .dropdownRollWidth {
          ul {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
          }
        }

        ul {
          li {
            padding-top: 6px;

            a {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
  /* ---End Nav Edits--- */
  .homepageSlideshowContainer {
    height: 345px;
  }
  /* -- Homepage Tabs -- */
  #homeTabs {
    .nav-tabs {
      .nav-item {
        &:nth-child(1) {
          a {
            padding: 22px 5%;
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          padding: 12px 0;
        }

        .nav-link {
          font-size: 1.2em;

          &.active {
            margin-bottom: 7px;
          }
        }
      }
    }

    .tab-pane h3 {
      font-size: 1.1em;
    }
  }
  /* -- End Homepage Tabs -- */
  /* -- Homepage Three Column --*/
  #homepageThreeColumn {
    div.d-md-flex {
      div {
        width: 48.3%;
      }

      div:nth-child(1) {
        padding-right: 1.7%;
      }

      div:nth-child(2) {
        padding-left: 1.7%;
        padding-right: 0;
      }

      div:nth-child(3) {
        width: 48.3%;
        margin: 30px auto 0;
      }

      .bucketCaption {
        height:254px;
        font-size: 1.02em;
      }
    }
  }
  /* -- End Homepage Three Column -- */
  /* -- Footer Edits -- */
  #footer {
    .footerColumn {
      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 50%;

        .footerColumnContainer {
          border-right: none;
        }
      }

      &:nth-child(3) {
        margin-top: 30px;
        width: 50%;

        .footerColumnContainer {
          padding-left: 0;
        }
      }

      &:nth-child(4) {
        width: 50%;
      }

      .socialMedia {
        width: 100%;
        display: inline-block;

        li {
          &:nth-child(2) {
            margin-right: 7px;
          }
        }
      }

      .footerCallout {
        margin-top: 30px;

        .circle {
          margin-left: -23%;
          height: 55px!important;
          width: 55px!important;

          i {
            font-size: 1.8rem;
          }
        }

        h5 {
          font-size: 1em;
          padding-right: 15px;
        }
      }
    }
  }

  #mainPageContent {
    .twoColumnImage {
      .rightColumn {
        img {
          float: none;
          margin: 3.5em auto 0;
          display: block;
        }
      }
    }

    .widgetRow {
      h3 {
        padding-top: 1rem;
      }

      .blogWidget {
        width: 100%;

        .blogBevel {
          .blogArticle {
            .thumb {
              width: 34%;
            }

            .teaser {
              width: 62%;
            }
          }
        }
      }

      .instagramWidget {
        width: 100%;
        height: 100%;
        text-align: center;
        padding-bottom: 15px;

        .instagramContainer {
          margin: 0 auto;
          display: inline-block;
          height: 317px!important;
        }
      }
    }
  }
  /*-- Accordion Edits --*/
  .accordion {
    h5 {
      font-size: 1em;
    }

    .card {
      .twoColumnImageAccordion {
        .leftColumn {
          padding: 3em 2.3em 1em;
        }

        img {
          float: none;
          display: block;
          margin: 0 auto 3.3em;
        }
      }
    }
  }
  /*-- End Accordion Edits --*/
  /*-- Sidebar Filter -->*/
  #mainListingContent {
    margin-top: 20px;

    .navDesktop {
      display: none;
    }

    .breadcrumbs {
      margin-top: 52px;
    }

    .navMobile {
      display: block;

      h3 {
        margin-bottom: 0;
      }

      .navFilterButton {
        text-align: center;
        background-color: #baafa5;
        color: #fff;
        display: block;
        padding: 10px;
        text-decoration: none!important;
        cursor: pointer;
        margin-top: 42px;
      }

      a.navFilterButton {
        &:hover {
          text-decoration: none!important;
        }
      }

      .navFilterButton[aria-expanded=true] i {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }

      .colorFilter {
        border-top: none;
      }

      .sizeFilter {
        border-top: none;
      }
    }

    .filterContainer {
      width: 100%;
      padding-right: 0;
      border-right: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #f7f4ef;
      padding: 15px;
      border-bottom: 6px solid #347258;

      .sidebarFilter {
        width: 48%;
        float: left;
      }
    }

    #productResults {
      width: 100%;
      padding-left: 0;

      .spacerProduct {
        display: none;
      }
    }

    #productListing {
      justify-content: space-around!important;
    }

    .mobileSidebarLinks {
      margin-bottom: 60px;
      display: flex;
      justify-content: space-around;

      .filterSidebarLink {
        font-family: "Montserrat", sans-serif;
        line-height: normal;
        margin-top: 0;

        div {
          float: left;
        }

        p {
          max-width: 190px;
          padding-top: 4px;
        }

        a {
          color: #00426b;
          font-weight: 500;
          font-size: 1.05em;

          &:hover {
            span {
              color: #347258;
            }
          }

          span {
            padding-top: 5px;
            font-weight: 700;
            font-size: 1.1em;
            color: #0078b2;
            text-transform: uppercase;
            display: block;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }

      .linkAccent {
        clear: both;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        transition: 0.2s;
        color: #fff;
        text-align: center;
        font-size: 1.25em;
        margin-right: 10px;
        background-image: linear-gradient(-180deg, #0078b2 25%, #00426b);

        i {
          padding: 5px 0 0 4px;
        }
      }
    }
  }

  #freeShipCallout {
    .freeShipMessage {
      background: url("../assets/imgs/freeshipBg_690px.jpg") no-repeat;
    }
  }
  /* Product Details Page */
  #mainDetailContent {
    #productInfo {
      .productImages {
        width: 44.05%;

        .slideViewContainer {
          width: 224px;

          .productThumbContainer {
            #customize-controls {
              height: 100px;
            }
          }
        }

        #slideView img {
          width: 100px;
        }
      }

      #productCartSection {
        h2 {
          font-size: 1.8em;
        }

        .skuLine {
          font-size: 0.9em;
        }

        .priceDetails {
          .mainPrice {
            padding: 8px 5% 8px 0;
          }
        }

        .volumePrice {
          padding: 16px 0 0 5%;

          p {
            &:first-child {
              font-size: 1.1em;
            }
          }

          table {
            font-size: 1rem;

            td,
            th {
              &:nth-child(2) {
                padding-left: 0;
              }

              &:nth-child(3) {
                padding-left: 0;
              }
            }

            thead {
              border-bottom: 1px solid #000;

              tr:nth-child(1) {
                display: none;
              }

              tr:nth-child(2) {
                display: table-row;
              }

              th:nth-child(3) {
                text-align: right;
              }
            }

            td:nth-child(3) {
              text-align: right;
            }
          }
        }

        .addToCart {
          label {
            font-size: 0.7rem;
          }

          button {
            font-size: 0.9rem;
            height: 52px;
            width: 67%;
            line-height: normal;
          }

          input[type="text"] {
            width: 27.42%;
          }

          .stockIndicator {
            font-size: 0.7rem;
          }
        }

        .customizePaperBtn {
          margin-top: 30px;

          p {
            font-size: 0.96rem;
          }

          .circle {
            height: 35px;
            font-size: 1.5rem;

            i {
              margin-top: 4px;
              padding-left: 4px;
            }
          }
        }

        .sampleSearchBtns {
          display: flex;
          margin-top: 40px;

          a {
            font-size: 0.8em;
          }
        }

        .paperFinderBtn {
          font-size: 0.93rem;
          margin-top: 18px;

          p {
            margin-right: 6px;
            width: 250px;
          }

          span {
            height: 21px;
            width: 21px;

            i {
              font-size: 0.8rem;
              margin: 5px 0 0 2px;
            }
          }
        }
      }
    }

    .relatedProducts {
      .tns-outer {
        width: 520px;
      }

      .allRelatedProducts {
        width: 520px;
      }
    }

    #productQuickLinks {
      ul {
        display: block;
        text-align: center;
        padding: 10px 0;

        a {
          padding: 8px 0;
        }
      }
    }
  }

  #cuttingModal {
    .modal-dialog {
      max-width: 690px;

      .modal-content {
        .nav-tabs{
          .nav-link{
            font-size:1.2rem;
          }
          li{
            &.nav-item{
              width:32.49%;
            }
          }
        }
        .tab-content {
          .standardFormats{
            .standardFormat{
              width:100%;
            }
          }
          .customCutAddToCart{
            form{
              justify-content:space-between;
              div:nth-child(2){
                width:auto;
                margin:0 8%;
              }
              .customPrice{
                margin-right:0;
              }
            }
          }
          .tab-pane {
            .customProductPreview{
              button{
                display:block;
              }
            }
            .customizationOptionsWrapper {
              display: block;
              .cuttingOptions{
              width:100%;

            }
            .customProductPreview{
              width:100%;
              margin:50px auto 0 auto;
              button{
                display:block;
                background-color:#00426b;
                border:none;
                color:#fff;
                letter-spacing:1px;
                font-size:1.3em;
                cursor:pointer;
                left:0;
                width:100%;
                padding:10px;
                &:hover{
                  text-decoration:underline;
                  i{
                    text-decoration:underline;
                  }
                }
              }
              .previewHeading{
                p{
                  display:none;
                }
              }
              a{
                display:none;
              }
              .mobilePreviewBox{
                display:none;
              }
              .previewBox{
                display:none;
                margin:50px auto;
              }
            }
            }
          }
        }
      }
    }
  }
}
/* ==========================================================================
550px to 767px
========================================================================== */
@include media-breakpoint-down(sm) {

  #preheader {
    text-align: center;
    position: relative;

    p {
      padding-top: 0;
    }

    ul {
      float: none;
      display: inline-block;
      padding-bottom: 0;
    }
  }

  header {
    margin-top: 2em;

    .logo {
      margin: 0 auto;
      display: block;
      margin-top: 0;
    }

    ul {
      padding: 0;
      float: none;
      text-align: center;
      margin-top: 1.5rem;

      li:last-child {
        margin-right: 0;
        margin-left: 30px;
      }
    }

    .search {
      text-align: center;

      form {
        float: none;
        display: inline-block;

        input[type=text] {
          width: 350px;
        }
      }
    }
  }

  #mainNav {
    ul {
      .dropdownContainer {
        padding-top: 1%;
      }

      .mainEnvelopeNav,
      .mainWideFormatNav {
        height: 66px;
        padding-top: 22px;
      }

      .mainPaperNav {
        padding-left: 5%;
        padding-right: 5%;

        i {
          position: absolute;
          margin-top: -7px;
          padding-left: 8px;
        }
      }
    }
  }

  .homepageSlideshowContainer {
    height: 255px;
  }
  /* Slideshow Button Adjustments */
  .tns-outer {
    width: 100%;

    .tns-controls {
      padding: 0 1rem;

      button {
        font-size: 2rem;
      }
    }
  }
  /* -- Homepage Tabs -- */
  #homeTabs {
    .nav-tabs {
      .nav-item {
        &:nth-child(2),
        &:nth-child(3) {
          padding: 10.5px 0;
        }

        .nav-link {
          font-size: 1.1em;
        }
      }
    }

    .tab-pane h3 {
      font-size: 1.1em;
    }

    #paper-tab-panel {
      .tabPaperColor {
        padding-bottom: 2.2em;
      }
    }

    #envelopes-tab-panel {
      .tabEnvColor {
        padding-bottom: 2.2em;
      }
    }

    #wide-tab-panel {
      .tabWideType {
        padding-bottom: 2.2em;
      }
    }
  }
  /* -- End Homepage Tabs -- */
  #homepageTwoColumn {
    div.d-md-flex {
      div:nth-child(1) {
        padding-right: 0;
        width: 100%;
      }

      div:nth-child(2) {
        padding-left: 0;
        width: 100%;
        margin-top: 96px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  /* -- Homepage Three Column --*/
  #homepageThreeColumn {
    div.d-md-flex {
      div {
        width: 100%;
      }

      div:nth-child(1) {
        padding-right: 0;
        margin-bottom: 96px;
      }

      div:nth-child(2) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 96px;
      }

      div:nth-child(3) {
        margin: 0 auto;
        width: 100%;
      }

      .bucketCaption {
        height:402px;
        font-size: 1.02em;
      }
    }
  }
  /* -- End Homepage Three Column -- */
  /* -- Footer Edits -- */
  #footer {
    .footerColumn {
      &:nth-child(4) {
        margin-top: 30px;
      }

      .footerCallout {
        margin-top: 30px;

        .circle {
          margin-left: -24%;
          margin-right: 15px;
          height: 40px!important;
          width: 40px!important;

          i {
            padding-top: 9px;
            font-size: 1.3rem;
          }
        }

        h5 {
          font-size: 0.8em;
          padding: 10px 10px 10px 0;
        }
      }
    }
  }

  #mainPageContent {
    .widgetRow {
      .blogWidget {
        .blogBevel {
          .blogArticle {
            padding: 0;

            .thumb {
              width: 100%;

              img {
                margin: 0 auto 20px;
                display: block;
              }
            }

            .teaser {
              width: 100%;
              text-align: center;
              padding: 0 10%;
              margin: 0;
            }
          }
        }
      }
    }
  }

  #mainListingContent h2 > span {
    max-width: 400px;
  }

  #mainListingContent {
    .filterContainer {
      .sidebarFilter {
        width: 100%;
        float: none;
      }

      .sizeFilter {
        border-top: 2px solid #6c6c6c;
      }
    }
  }

  #freeShipCallout {
    font-size: 1.5em;

    .freeShipMessage {
      background: url("../assets/imgs/mobileFreeShipBg.jpg") no-repeat;

      p {
        &:first-child {
          width: 19%;
          display: inline-block;
        }

        &:last-child {
          width: 81%;
        }
      }

      .mobileMessage {
        width: 54%;
        margin: 0 auto;
        display: block;
        padding: 5px 0;
      }

      .mobile {
        display: flex;

        span {
          height: 42px;
          width: 42px;
          border: 2px solid #00426b;
          border-radius: 50%;
          display: inline-block;
          margin-top: -1px;

          i {
            margin-top: 7px;
            display: block;
          }
        }
      }

      p {
        text-align: center;
        width: auto;
        float: left;

        span {
          display: none;
        }
      }
    }
  }

  #mainDetailContent {
    #productInfo {
      .productImages {
        width: 75%;
        margin: 0 auto;
      }

      .desktopProductTitle {
        display: none;
      }

      .mobileProductTitle {
        display: block;

        h2 {
          font-size: 1.8em;
        }

        .skuLine {
          color: #40886a;
          font-size: 1em;

          span {
            color: #000;
          }
        }
      }

      #productCartSection {
        margin-top: 50px;
        width: 100%;

        .volumePrice {
          table {
            font-size: 1rem;

            th {
              font-size: 1rem;
            }

            td,
            th {
              &:nth-child(2) {
                padding-left: 6%;
              }
            }
          }
        }

        .addToCart {
          label {
            font-size: 1rem;
          }

          button {
            font-size: 1.1rem;
          }

          .leftAddToCart {
            width: 100%;
          }

          .stockIndicator {
            width: 100%;
            float: none;
            margin-left: 0;
            display: flex;
            justify-content: space-between;
            padding-top: 5px;
            font-size: 0.9rem;
            margin: 0 0 20px;
          }
        }

        .sampleSearchBtns {
          clear: both;
          display: block;

          a {
            width: 100%;
            margin-right: 0;
            float: none;
            height: 52px;
            line-height: 34px;
            margin-bottom: 5px;
            font-size: 1rem;
            display: block;

            &:last-child {
              margin-bottom: 20px;
            }
          }
        }

        .customizePaperBtn {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .circle {
            height: 50px;
            width: 50px;

            i {
              font-size: 2.2rem;
              padding: 2px 0 0 5px;
            }
          }
        }
      }
    }

    .relatedProducts {
      .allRelatedProducts {
        .relatedProduct {
          width: 160px;
          margin-bottom: 15%;
        }
      }

      .relatedProduct {
        p {
          &.productCardTitle {
            font-size: 1rem;
          }

          &:last-child {
            font-size: 0.8em;
          }
        }
      }

      .tns-outer {
        width: 344px;
      }

      .allRelatedProducts {
        width: 344px;
      }
    }

    #productQuickLinks {
      ul {
        text-align: center;
      }
    }

    #productDetailsDesktop {
      display: none;
    }

    #mobileProductDetails {
      display: block;
    }
  }
  #cuttingModal {
    .modal-dialog {
      max-width: 510px;
      margin:0 auto;
      .modal-content{
        .exitCustomCutting{
          padding-right:1rem;
          font-size:1.8rem;
        }
        .nav-tabs{
          li{
            &.nav-item{
              width:32.1%;
            }
          }
          .nav-link{
            font-size:.8rem;
          }
        }
        .cutRestrictions{
          font-size:.85rem;
        }
        .tab-content{
          .standardFormats{
            .returnCutOptions{
              font-size:.8rem;
            }
            .formatDetails{
              .title{
                font-size:1.1rem;
              }
              p, button{
                font-size:.85rem;
              }
            }
          }
          .customCutAddToCart{
            form{
              flex-wrap:wrap;
              div:nth-child(2){
                margin:0;
              }
              div:nth-child(3){
                margin:25px 0px 40px 0px;
                width:100%;
                button{
                  width:100%;
                }
              }
            }
          }
          .tab-pane{
            h4{
              font-size:1.1rem;
              margin-top:40px;
            }
            .customizationOptionsWrapper{
              .customProductPreview.normalPreview{
                display:none;
              }
              .formLayoutWrapper{
                font-size:.85rem;
              }
              .customProductPreview{
                margin:40px auto 0 auto;
              }
              .cuttingOptions{
                button{
                  font-size:.8rem;
                  padding:10px 0.55rem 10px 0.75rem
                }
                .normalPreviewBox{
                  display:none!important;
                }
                .standardFormatBtn{
                  font-size:.8rem;
                }
                .currentSettingsContainer{
                  table{
                    position:relative;
                    td{
                      padding-left:25px;
                      font-size:.85rem;
                      i{
                        position:absolute;
                        left:0;
                        margin-top:4px;
                      }
                    }
                    td:nth-child(2){
                      padding:0 15px;
                    }
                  }
                }

              }

            }

            .customizationOptionsWriteWrapper{
              display:block;
              .customCutTextarea{
                width:100%;
              }

              .clearAllBtn{
                width:100%;
                margin-top:40px;
                button{
                  font-size:.8rem;
                }
              }
            }

          }
        }
      }
    }
  }
}
/* ==========================================================================
550px and less
========================================================================== */
@media (max-width: 550px) {
  header {
    ul {
      li {
        margin: 0!important !important;
        display: block;
        padding-bottom: 5px;
      }
    }

    .search {
      form {
        width:100%;
        display: inline-block;
        .form-item-search-block-form{
          width:84%;
          margin-right:1%;
        }
        input[type=text] {
          width: 100%;
        }
      }
    }
  }

  #mainNav {
    ul {
      margin-top: 2em;

      li {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1%;
      }

      .mainEnvelopeNav,
      .mainWideFormatNav {
        height: auto;
        padding-top: 11px;
      }

      .mainPaperNav {
        padding-left: 0;
        padding-right: 0;

        i {
          position: relative;
          margin: 0;
          padding: 0;
        }
      }

      .dropdownContainer {
        .dropdownEnvBrand,
        .dropdownEnvSize,
        .dropdownPaperBrand,
        .dropdownPaperColor,
        .dropdownPaperSize,
        .dropdownPaperType,
        .dropdownPaperWeight,
        .dropdownRollWidth,
        .dropdownWideType {
          width: 97.2%;
        }

        .dropdownPaperSize {
          ul {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
          }
        }

        .dropdownEnvColor {
          ul {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
          }
        }
      }
    }
  }

  .homepageSlideshowContainer {
    height: auto;
  }

  #homeTabs {
    .nav-tabs {
      .nav-link {
        font-size: 0.8em;
      }
    }
  }

  #homepageThreeColumn {
    div.d-md-flex {
    .bucketCaption{
      height:85%;
    }
    }
  }

  #freeShipCallout {
    .freeShipMessage {
      .mobileMessage {
        width: 62%;
      }
    }
  }

  #freeShipCallout {
    font-size: 1.5em;

    .freeShipMessage {
      background: url("../assets/imgs/mobileFreeShipBg.jpg") no-repeat;

      p {
        &:first-child {
          width: 18%;
          display: inline-block;
        }

        &:last-child {
          width: 81%;
        }

        span {
          margin-right: 0;
        }
      }
    }
  }
  /* -- Footer Edits -- */
  #footer {
    text-align: center;

    .footerColumn {
      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(2) {
        width: 100%;

        .footerColumnContainer {
          padding: 0;
          margin-top: 40px;
          border: none;
          text-align: center;
        }
      }

      &:nth-child(3) {
        width: 100%;
        margin-top: 40px;

        .footerColumnContainer {
          padding: 0;
        }
      }

      &:nth-child(4) {
        width: 100%;
      }

      .footerCallout {
        margin-top: 30px;
        position: relative;

        .circle {
          display: block;
          float: none;
          margin: 0 auto 10px;
          height: 60px!important;
          width: 60px!important;

          i {
            padding-top: 12px;
            font-size: 1.9rem;
          }
        }

        h5 {
          font-size: 1em;
          padding: 10px;
        }
      }
    }
  }
  /*-- Accordion Edits --*/
  .accordion {
    h5 {
      font-size: 0.9em;

      span {
        &:first-child {
          width: 88%;
        }
      }
    }
  }
  /*-- Products --*/
  #mainListingContent h2 > span {
    max-width: 350px;
  }

  #mainListingContent {
    #productListing {
      .product {
        width: 200px;
        position: relative;

        .productDetailLinks {
          padding-bottom: 95px;
        }
      }

      .cartButtons {
        position: absolute;
        bottom: 0;
      }
    }
  }

  #mainListingContent {
    .mobileSidebarLinks {
      margin-top: 30px;
      display: block;
      text-align: center;

      .filterSidebarLink {
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;

        p {
          width: 100%;
          display: inline-block;
        }

        div {
          float: none;
          margin: 0 auto;
        }
      }
    }

    .pagination {
      clear: both;
      text-align: center;
    }
  }

  #mainDetailContent {
    #productInfo {
      #productCartSection {
        .volumePrice {
          table {
            td,
            th {
              &:nth-child(2) {
                padding-left: 6.5%;
              }
            }
          }
        }
      }
    }
  }
  #cuttingModal{
     .modal-dialog{
       .modal-content{
         .exitCustomCutting{
           display:none;
         }
         .modal-body{
           margin-top:44px;
         }
         #cuttingTabPanel{
           margin-top:20px;
         }
         .nav-tabs{
           li.nav-item{
             &.nav-item{
               width:100%;
               margin:0;
               margin-bottom:5px;
             }
           }
           .nav-link{
             &:hover{
               opacity:.85;
             }
             &.active{
               padding-bottom:12px;
             }
           }
         }
       }
     }
   }
   .modalExit{
     position:fixed;
     top:0;
     z-index: 2001;
     width:100%;
     background-color:#000;
     color:#fff;
     border:none;
     padding:10px;
     cursor:pointer;
     font-weight:600;
   }
}
/* ==========================================================================
475px and less
========================================================================== */
@media (max-width: 475px) {
  #mainListingContent {
    #productResults {
      .resultsPaneSort {
        .itemsPerPage {
          float: none;
        }

        .itemsSortBy {
          display: block;
          float: none;
          clear: both;
          margin: 15px 0 0;
        }
      }
    }

    #productListing {
      .product {
        width: 248px;
      }
    }
  }

  #homeTabs {
    .nav-tabs {
      .nav-item {
        &:nth-child(3) {
          padding: 0;

          a {
            padding: 22px 5%;
          }
        }
      }
    }
  }

  #freeShipCallout {
    .freeShipMessage {
      .mobileMessage {
        width: 70%;
      }
    }
  }

  #mainDetailContent {
    #productInfo {
      #productCartSection {
        .priceDetails {
          .mainPrice {
            p {
              &:first-child {
                padding-top: 6px;
                font-size: 1.3em;
              }
            }
          }

          .volumePrice {
            padding-top: 8px;
          }
        }
      }
    }

    .relatedProducts {
      .tns-outer {
        width: 160px;
      }

      .allRelatedProducts {
        width: 160px;
      }
    }

    #productQuickLinks {
      ul {
        text-align: center;
        columns: 1;

        li {
          margin: 10px 0;
        }
      }
    }

    #mobileProductColors {
      a {
        width: 70%;
      }

      .colorOption {
        width: 100%;
      }
    }
  }
  #cuttingModal{
     .modal-dialog{
       .modal-content{
         .nav-tabs{
           li.nav-item{
             &.nav-item{
               width:100%;
             }
           }
         }
         .tab-content{
           .tab-pane{
             .standardFormats{
               .standardFormat{
                 .formatDetails{
                   .title{
                     font-size:1rem;
                   }
                   p{
                     font-size:.9rem;
                   }
                 }
               }
             }
             .normalPreviewBox{
               display:none!important;
             }
             .mobilePreviewBox{
               display:block;
               margin:50px auto!important;
             }
             .customizationOptionsWrapper{
             .formLayoutWrapper{
               display:block;
               .cuttingFormRight{
                 margin:15px 0px 25px;
                 width:96px;
               }
             }
           }
           }
         }
       }
     }
   }
}
@media (max-width: 430px) {
  #freeShipCallout {
    font-size: 1.2rem;
    line-height: normal;

    .freeShipMessage {
      .mobile {
        span {
          height: 35px;
          width: 35px;
          border: 2px solid #00426b;
          border-radius: 50%;
          display: inline-block;

          i {
            margin-top: 7px;
            display: block;
          }
        }
      }

      .mobileMessage {
        width: 70%;
      }
    }
  }

  #homeTabs {
    .nav-tabs {
      .nav-item {
        &:nth-child(2) {
          padding: 8px 0;
        }

        .nav-link {
          font-size: 0.85rem;
        }
      }
    }
  }
  #cuttingModal .modal-dialog .modal-content .tab-content .customCutAddToCart form div:nth-child(2){
    width:28%;
  }
}
@media (max-width: 375px) {
  #homeTabs {
    .nav-tabs {
      .nav-item {
        &:nth-child(2) {
          padding: 9px 0;
        }
      }
    }
  }
}
/* ==========================================================================
320px and less
========================================================================== */
@media (max-width: 320px) {
  #preheader {
    font-size: em(14);
  }

  header {
    .search {
      form {
        display: inline-block;
        width: 80.6%;

        input[type=text] {
          width: 80.6%;
        }
      }
    }
  }

  #homeTabs {
    .nav-tabs {
      .nav-link {
        font-size: 0.58em;
        font-weight: 500;
      }
    }
  }

  #freeShipCallout {
    .freeShipMessage {
      .mobileMessage {
        width: 77%;
      }
    }
  }

  #mainContent {
    .widgetRow {
      .instagramWidget {
        .instagramContainer {
          height: 260px!important;
        }
      }
    }
  }

  #mainDetailContent {
    #productInfo {
      .productImages {
        .slideViewContainer {
          .productThumbContainer {
            #customize-controls {
              .prev {
                margin-left: -38px;
              }

              .next {
                margin-right: -38px;
              }
            }
          }
        }
      }
    }
  }
  #cuttingModal{
     .modal-dialog{
       .modal-content{
          .tab-content{
             .tab-pane{
                .customizationOptionsWrapper{
                  .cuttingOptions{
                     .currentSettingsContainer{
                        overflow:auto;
                        white-space:nowrap;
                        width:276px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
}
