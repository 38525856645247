/* ==========================================================================
Bootstrap Overrides
========================================================================== */
@import "node_modules/bootstrap/scss/bootstrap"; //px to em conversion
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i|Montserrat:300,400,500,600&subset=latin-ext');
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
/* ==========================================================================
Color Declarations
========================================================================== */
$mainFontColor: #000;
$black: #000;
$white: #fff;
$navy: #00426b;
$red: #a61c20;
$lighterNavy: #005087;
$blue: #0078b2;
$green: #347258;
$lighterGreen: #40886a;
$lightGreen: #6B9885;
$brightRed: #db003a;
$lightTan: #f7f4ef;
$paleGray: #fcfbfa;
$darkerPaleGray: #f7f5f3;
$darkestPaleGray: #ebe6e2;
$photoOverlayColor: #EAE6E2;
$productListingBg: #faf8f4;
/* Swatches */
.swatchWhite {
  background-color: #fff;
}

.swatchCream {
  background-color: #fcf5de;
}

.swatchYellow {
  background-color: #ffe500;
}

.swatchOrange {
  background-color: #f79222;
}

.swatchRed {
  background-color: #ed1c24;
}

.swatchPink {
  background-color: #f69695;
}

.swatchPurple {
  background-color: #892890;
}

.swatchBlue {
  background-color: #007cc4;
}

.swatchGreen {
  background-color: #77c046;
}

.swatchBlack {
  background-color: $black;
}

.swatchGrey {
  background-color: #919396;
}

.swatchBrown {
  background-color: #955903;
}

.swatchSilver {
  background-color: #828487;
}

.swatchGold {
  background-color: #dba748;
}

.swatchCopper {
  background-color: #c15d1a;
}

.swatchIvory {
  background-color: #fffff0;
}

.fillIn {
  transition: 0.2s;
}
// Animate the size, inside

.fillIn:focus,
.fillIn:hover {
  background-color: $navy;
  color: #fff!important;
  border: 3px solid $black;
}
/* ==========================================================================
Mixins
========================================================================== */
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
/* ==========================================================================
Buttons
========================================================================== */
.btn-navy-outline {
  color: $navy;
  border: 3px solid $navy;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  display: block;
  padding: 14px 0 12px;

  &:hover {
    color: $navy;
    text-decoration: none;
  }
}
/* ==========================================================================
Mobile
========================================================================== */
#mobileProductDetails,
.mobile,
.mobileProductTitle,
.navMobile,
.noStock,
.modalExit {
  display: none;
}
/* ==========================================================================
Body
========================================================================== */
body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

/* ==========================================================================
Alert
========================================================================== */
#alert{
  background-color:#fff;
  color:$red;
  padding:15px 0px;

  i{
    color:$red;
    font-size:3rem;
    margin-right:1rem;
  }

  p{
    margin-bottom:0px;
    font-size:1.2rem;
    line-height:normal;
  }
}

/* ==========================================================================
Pre-header
========================================================================== */
#preheader {
  background-color: $black;
  color: $white;
  font-size: em(20);
  letter-spacing: 0.5px;

  p {
    margin-bottom: 0;
    padding: 0.5rem 0;
  }

  ul {
    text-decoration: none;
    list-style: none;
    padding: 0.5rem 0;
    margin-bottom: 0;
    float: right;
  }

  li {
    float: left;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.15s;

    &:hover {
      text-decoration: none;
      color: #6B9885;
    }
  }

  span {
    font-family: 'Lora', serif;
    font-style: italic;
    font-size: em(14);
    -webkit-font-smoothing: antialiased;
  }
}
/* ==========================================================================
Header
========================================================================== */
header {
  margin-top: em(72);

  ul {
    list-style: none;
    float: right;

    li {
      display: inline-block;
      margin-left: 30px;

      a {
        color: $mainFontColor;
        font-weight: 300;
        font-size: em(18);
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;

        &:hover {
          text-decoration: none;
          border-bottom: 1px solid $black;
          color: $mainFontColor;
        }
      }
    }
  }

  .search {
    form {
      float: right;
      color: $mainFontColor;
      padding-top: 0.5em;

      .form-item-search-block-form,
      #edit-actions{
        float:left;
      }

      input[type=text] {
        border: none;
        background-color: $darkerPaleGray;
        height: 37px;
        border-radius: 20px 0 0 20px;
        text-align: right;
        padding-right: 1rem;
        width: 395px;
        margin-top:0px;
        @include placeholder {
          color: $mainFontColor;
          text-transform: uppercase;
        }
      }

      button {
        border: none;
        background-color: $black;
        height: 37px;
        display: inline-block;
        border-radius: 0 20px 20px 0;
        margin-left: 5px;
        width: 40px;
        cursor: pointer;

        i {
          color: $white;
          font-size: 1.5em;
          margin-left: -5px;
          padding-top: 1px;
        }
      }
    }
  }

  .logo {
    width: 100%;
    height: auto;
    max-height: 51px;
    margin-top: 40px;
    max-width: 448px;
  }
}
/* ==========================================================================
Main Navigation
========================================================================== */
#mainNav {
  width: 100%;
  display: inherit;

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
    margin: em(40) 15px 0;
    font-size: em(19);
    letter-spacing: 0.5px;
    position: relative;

    li {
      float: left;
      width: 33%;
      text-align: center;
      margin-right: 0.5%;

      a {
        text-transform: uppercase;
        color: $white;
        display: block;
        padding: 11px 0;

        &:hover {
          color: $white;
          text-decoration: none;
        }
      }

      a.mainPaperNav {
        background-color: $navy;
      }

      a.mainEnvelopeNav {
        background-color: $black;
      }

      a.mainWideFormatNav {
        background-color: $green;
      }
    }

    li:last-child {
      margin-right: 0;
    }

    .dropdownContainer {
      background-color: $paleGray;
      position: absolute;
      width: 100%;
      padding-top: 0.5%;
      display: none;
      z-index: 2;

      p {
        text-align: left;
        color: $mainFontColor;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
        border-bottom: 2px solid #cecdcc;
        margin-bottom: 0;
      }

      li {
        width: auto;
        padding: 0.1em 0;
      }

      .learnMore,
      .viewAll {
        font-weight: 600;
      }

      .navHeadBar {
        background-color: $darkestPaleGray;
        margin-bottom: 0.8em;

        a {
          color: $navy;
          padding: 5px 0;
        }
      }

      .dropdownColumn {
        float: left;
        margin: 0 1.47%;
      }

      .dropdownColumn ul {
        margin: 0.15em 0 0;
      }

      .dropdownPaperColor {
        width: 14.5%;

        ul {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
      }

      .dropdownPaperSize {
        width: 10.5%;
      }

      .dropdownPaperWeight {
        width: 22.27%;

        ul {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
      }

      .dropdownPaperBrand {
        width: 18.89%;

        ul {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
      }

      .dropdownPaperType {
        width: 19.14%;

        ul {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
      }

      .dropdownEnvSize {
        width: 30.35%;

        ul {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
      }

      .dropdownEnvBrand {
        width: 30.35%;

        ul {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
      }

      .dropdownEnvColor {
        width: 30.35%;

        ul {
          columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
      }

      .dropdownWideType {
        width: 30.35%;
      }

      .dropdownRollWidth {
        width: 30.35%;

        ul {
          columns: 3;
          -webkit-columns: 3;
          -moz-columns: 3;
        }
      }

      .dropdownWidePrinterInk {
        width: 30.35%;
      }

      ul {
        li {
          float: none;

          a {
            color: #000;
            font-size: 0.8rem;
            text-transform: capitalize;
            padding: 0;
            text-align: left;
            letter-spacing: 0;

            &:hover {
              font-weight: 600;
              color: $mainFontColor;
              text-decoration: underline;
            }
          }
        }
      }

      .mainNavCallout {
        clear: both;
        font-size: 1rem;
        padding-top: 0.8rem;
        text-align: left;
        font-family: 'Montserrat', sans-serif;

        a {
          color: $navy;
          padding: 0;
        }

        .circle {
          height: 53px;
          width: 53px;
          border-radius: 50%;
          border: 2px solid $navy;
          display: block;
          text-align: center;
          float: left;
          margin-left: 0.8rem;

          i.fa-chevron-right {
            color: $navy;
            font-weight: bold;
            padding: 8px 0 0 6px;
            font-size: 2.3rem;
          }
        }

        p {
          margin: 0 0 2rem 0.8rem;
          font-size: 0.95rem;
          line-height: 1.4rem;
          text-transform: none;
          color: $navy;
          padding: 3px 5px 3px 0;
          border-top: 1px solid $navy;
          border-bottom: 1px solid $navy;
          display: inline-block;
          font-weight: 500;
          float: left;

          span {
            text-transform: uppercase;
            display: block;
            font-size: 1.05rem;
          }
        }
      }
    }

    .wideFormatNav .mainNavCallout {
      float: right;
      margin-right: 1.47%;
    }
  }

  .paperNav.mobilehover .dropdownContainer,
  .paperNav:hover .dropdownContainer {
    display: block;
    animation-name: slideInDown;
  }

  .envelopeNav.mobilehover .dropdownContainer,
  .envelopeNav:hover .dropdownContainer {
    display: block;
    left: 0;
    animation-name: slideInDown;
  }

  .wideFormatNav.mobilehover .dropdownContainer,
  .wideFormatNav:hover .dropdownContainer {
    display: block;
    left: 0;
    animation-name: slideInDown;
  }

  .animated {
    animation-duration: 0.2s;
    animation-fill-mode: both;
  }
  @keyframes slideInDown {
    from {
      opacity: 0;
      transform: translate3d(0, 10%, 0);
      visibility: visible;
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }
}
/* ==========================================================================
Homepage Slideshow
========================================================================== */
.homepageSlideshowContainer {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 6px 0 0;
  padding: 0 15px;
  height: 600px;
  outline: none;

  img {
    max-width: 100%;
    height: auto;
  }
}

.tns-outer {
  width: 100%;

  .tns-controls {
    position: absolute;
    pointer-events: none;
    filter:progid:DXImageTransform.Microsoft.AlphaImageLoader();
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    left: 0;

    button {
      background: none;
      border: none;
      pointer-events: auto;
      font-size: 2.5rem;
      color: rgba(255,255,255,.6);
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 12px;
    z-index: 1;
    width: 100%;
    left: 0;

    button {
      border: none;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      background-color: rgba(255,255,255,.6);
      padding: 0;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    .tns-nav-active {
      background-color: #fff;
    }
  }
}
/* ==========================================================================
Announcement
========================================================================== */
#announcement {
  margin-top: 6px;

  img {
    max-width: 100%;
    height: auto;
  }
}
/* ==========================================================================
Homepage Tabs
========================================================================== */
#homeTabs {
  margin-top: 96px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;

  .nav-tabs {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .nav-link {
      border-radius: 0;
      text-align: center;
      font-size: 1.8em;
      padding: 22px 0;
      color: #fff;
      text-transform: uppercase;
      border: none;

      &.active {
        background-color: transparent;
        margin-bottom: 12px;
      }
    }
  }

  .nav-item {
    flex: 1;
    margin-right: 1%;
    line-height: normal;

    &:last-child {
      margin-right: 0;
    }
  }

  .tab-pane {
    background-color: $lightTan;
    padding: 1.5em 0;

    ul {
      list-style: none;
      padding: 0;
      font-family: 'Lora', serif;
      font-size: em(18);
      columns: 4;
      -webkit-columns: 4;
      -moz-columns: 4;

      a {
        color: $black;
      }

      li {
        padding: 4px 0 0;
      }
    }

    h3 {
      text-transform: uppercase;
      font-size: em(22);
      font-weight: 500;
      border-bottom: 1px solid $black;
    }
  }

  p {
    margin: -10px 0 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.1em;

    a {
      color: $black;
    }
  }

  .paperTab {
    background-color: $navy;
  }

  .envTab {
    background-color: $black;
  }

  .wideTab {
    background-color: $green;
  }

  .tab-content {
    .circle {
      border-radius: 50%;
      width: 19px;
      height: 19px;
      display: block;
      float: left;
      border: 1px solid $black;
      margin: 3px 5px 0 0;
    }
  }

  #paper-tab-panel {
    .tabPaperColor {
      padding: 0 15px;
    }

    .tabPaperSize {
      padding: 0 15px 2.2em;
    }

    .tabPaperType {
      padding: 0 15px;
    }

    &.active {
      border-top: 7px solid $navy;
      border-bottom: 7px solid $navy;
    }
  }

  #envelopes-tab-panel {
    .tabEnvColor {
      padding: 0 15px;
    }

    .tabEnvSize {
      padding: 0 15px 2.2em;
    }

    .tabEnvBrand {
      padding: 0 15px;

      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }

    &.active {
      border-top: 7px solid $black;
      border-bottom: 7px solid $black;
    }
  }

  #wide-tab-panel {
    .tabWideType {
      padding: 0 15px;

      ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
      }
    }

    .tabWideWidth {
      padding: 0 15px 2.2em;
    }

    .tabWidePrinterInk {
      padding: 0 15px;

      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }

    &.active {
      border-top: 7px solid $green;
      border-bottom: 7px solid $green;
    }
  }
}
/* ==========================================================================
Main Content
========================================================================== */
#mainPageContent {
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 2.2em;
    overflow: hidden;
    letter-spacing: 1px;
    padding: 87px 0;
  }

  h2 > span {
    position: relative;
    display: inline-block;
  }

  h2 > span:after,
  h2 > span:before {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 3px solid;
    width: 591px;
    /* half of limiter*/
    margin: 0 12px;
  }

  h2 > span:before {
    right: 100%;
  }

  h2 > span:after {
    left: 100%;
  }

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.3em;
    border-bottom: 1px solid #9b9b9b;
    margin-bottom: 1.25em;
    padding-bottom: 2px;
    font-weight: 500;
  }

  p {
    font-family: 'Lora', serif;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.6em;

    .boldMainCopyLink {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      color: $mainFontColor;

      i {
        font-size: 0.7em;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .twoColumnImage {
    img {
      max-width: 100%;
      height: auto;
    }

    .rightColumn {
      img {
        margin-top: 1.6em;
        float: right;
      }
    }
  }

  .accordion {
    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 0.95em;
      line-height: 1.5em;
    }
  }

  .widgetRow {
    margin-top: 72px;

    h3 {
      border-bottom: none;
      text-transform: none;
      text-align: center;
      margin-top: 0.7em;
      font-size: 1.7em;
      font-weight: 500;
      margin-bottom: 0.2em;
    }

    .blogWidget {
      background: url("../images/paperGrain.jpg");
      background-size: cover;
      width: 64.66%;
      padding-bottom: 0.5rem;

      .blogBevel {
        background: url("../images/blogBevel.svg") no-repeat;
        background-size: contain;
        margin: 0 0.8em;
        padding-top: 2.5rem;

        .blogArticle {
          font-family: 'Montserrat', sans-serif;
          padding-left: 5.4%;
          padding-right: 3.2%;
          display: block;

          .thumb {
            float: left;
            width: 20.5%;

            img {
              border: 4px solid #fff;
            }
          }

          .teaser {
            margin-left: 1.73%;
            float: left;
            width: 76%;

            h4 {
              padding-bottom: 1px;
              border-bottom: 1px solid #626262;
              text-transform: uppercase;
              font-size: 1.4em;
            }

            p {
              font-family: 'Montserrat', sans-serif;
              font-size: 1rem;
              line-height: 1.4rem;
              font-weight: 500;
              margin-bottom: 2.3em;

              a {
                text-transform: uppercase;
                color: #000;
                border-bottom: 2px solid $black;
                font-weight: 600;
                font-size: 1.1rem;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }

    .instagramWidget {
      background-color: $green;
      width: 29.33%;
      position: relative;

      .instagramContainer {
        height: 317px;
        overflow: hidden;

        img {
          max-width: 100%;
          height: auto;
        }

        .overlay {
          position: absolute;
          pointer-events: none;
          filter:progid:DXImageTransform.Microsoft.AlphaImageLoader();
        }
      }

      h3 {
        color: #fff;
      }
    }
  }
}
/* ==========================================================================
Accordion
========================================================================== */
.accordion {
  margin-top: 50px;

  p {
    font-family: 'Montserrat', sans-serif;
  }

  .card {
    border-radius: 0;
    color: #fff;
    border: none;

    .card-header {
      border-radius: 0;
      border: none;
      padding: 0;
      margin-top: 22px;

      h5 {
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;

        button {
          text-transform: uppercase;
          color: #fff;
          text-decoration: none;
          font-size: 1.4em;
          letter-spacing: 1px;
          width: 100%;
          text-align: left;
          background-color: $green;
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            &:first-child {
              width: 95%;
              white-space: normal;
              display: block;
            }

            &:last-child {
              font-size: 2em;
              line-height: normal;
            }
          }

          &.collapsed {
            background-color: $navy;

            .accordionIcon {
              -webkit-transform: rotate(-90deg);
              -moz-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              -o-transform: rotate(-90deg);
              transform: rotate(-90deg);
            }
          }
        }
      }
    }

    .card-body {
      color: #000;
      background-color: $lightTan;
      margin-top: 4px;
      border-top: 6px solid $green;
      border-bottom: 6px solid $green;
      margin-bottom: 22px;
      padding: 0;
    }

    .twoColumnImageAccordion {
      .leftColumn {
        padding: 3em 2.3em 1em 3.3em;

        h3 {
          margin-bottom: 0.5em!important;
        }
      }

      img {
        float: right;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .fullColumn {
    padding: 3em 3.3em;
  }

  button {
    display: block;
  }
}
/* ==========================================================================
Homepage Paper Finder Link
========================================================================== */
.inner #homePaperFinderLink {
  margin-top: 96px;
}

#homePaperFinderLink {
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-align: center;

  p {
    font-size: em(22);
    border-top: 3px solid $black;
    border-bottom: 3px solid $black;
    padding: 10px 0;
    margin-bottom: 0;

    a {
      color: $black;
    }

    span {
      font-weight: 600;
      font-size: 1.6rem;
      text-transform: uppercase;
    }
  }
}
/* ==========================================================================
Homepage Two Column Section
========================================================================== */
#homepageTwoColumn {
  margin: 96px auto;

  a {
    color: $black;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6em;
    text-align: center;
    margin-top: 40px;
    overflow: hidden;
    letter-spacing: 1px;
  }

  h2 > span {
    position: relative;
    display: inline-block;
  }

  h2 > span:after,
  h2 > span:before {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 3px solid;
    width: 591px;
    /* half of limiter*/
    margin: 0 12px;
  }

  h2 > span:before {
    right: 100%;
  }

  h2 > span:after {
    left: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}
/* ==========================================================================
Homepage Three Column Section
========================================================================== */
#homepageThreeColumn {
  margin: 96px auto;

  a {
    color: $black;
    display: block;
    position: relative;

    &.bucketAnimation {
      &:hover {
        .bucketCaption {
          opacity: 1;
        }

        .bucketOverlay {
          opacity: 0.8;
        }
      }
    }
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6em;
    text-align: center;
    margin-top: 40px;
    overflow: hidden;
    letter-spacing: 1px;
  }

  h2 > span {
    position: relative;
    display: inline-block;
  }

  h2 > span:after,
  h2 > span:before {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 3px solid;
    width: 591px;
    /* half of limiter*/
    margin: 0 12px;
  }

  h2 > span:before {
    right: 100%;
  }

  h2 > span:after {
    left: 100%;
  }

  .bucketOverlay {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: auto;
    transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
  }

  .bucketCaption {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-weight: 400;
    position: absolute;
    z-index: 1;
    height: 278px;
    font-size: 1.02em;
    padding: 0 16%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;

    p {
      max-width: 310px;
    }

    p:last-child {
      font-weight: 600;
      border-bottom: 2px solid $black;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width:352px;
  }
}
/* ==========================================================================
LATEST PROJECTS
========================================================================== */
#latestProjects {
  margin: 96px auto 180px;
  text-align: center;

  h2 {
    text-align: center;
    font-family: 'Lora', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 1.7em;
    letter-spacing: 1px;
    overflow: hidden;
    margin-bottom: 0;
  }

  h2 > span {
    position: relative;
    display: inline-block;
  }

  h2 > span:after,
  h2 > span:before {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 3px solid;
    width: 591px;
    /* half of limiter*/
    margin: 0 12px;
  }

  h2 > span:before {
    right: 100%;
  }

  h2 > span:after {
    left: 100%;
  }

  .latestProjectsBorder {
    border-bottom: 3px solid $black;
  }

  .latestProjectSlider {
    margin: 50px auto;

    .latestProjectItem {
      position: relative;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      text-transform: uppercase;

      a {
        color: $black;
      }

      .circleAnimation {
        opacity: 0;
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        -webkit-transition: all 0.15s ease-in-out;

        span {
          border-bottom: 2px solid $black;
        }

        p {
          margin-bottom: 0;
        }

        .circleBorder {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 140px;
          width: 140px;
          margin: 10px;
          border: 1px solid $black;
          border-radius: 50%;
        }
      }

      &:hover {
        .circleAnimation {
          width: 162px;
          height: 162px;
          background-color: rgba(234,230,224, .8);
          opacity: 1;
        }
      }
    }

    img {
      overflow: hidden;
      border-radius: 50%;
    }
  }

  .tns-outer {
    width: 100%;

    .tns-controls {
      position: absolute;
      outline: none;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      left: 0;
      padding: 0 15px;

      button {
        background: none;
        border: none;
        font-size: 2.5rem;
        color: $black;
        padding: 0;
        margin-top: -38px;
        display: block;
      }
    }
  }

  .tns-inner {
    max-width: 86%;
    overflow: hidden;
    display: inline-block;
  }
}
/* ==========================================================================
Footer
========================================================================== */
#footer {
  background: url("../images/woodGrainBg.jpg") repeat-y;
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  padding: 57px 0 100px;

  .footerColumn {
    p {
      margin-bottom: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0;

      li {
        padding: 4px 0;
        font-size: 1.1em;

        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        color: $green;
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 1.1em;
      font-weight: 600;
    }

    &:nth-child(1) {
      width: 23.75%;
    }

    &:nth-child(2) {
      width: 29.16%;
      text-align: center;

      .footerColumnContainer {
        display: inline-block;
        text-align: left;
        border-left: 2px solid $navy;
        border-right: 2px solid $navy;
        padding: 0 20%;
      }
    }

    &:nth-child(3) {
      width: 27.16%;

      .footerColumnContainer {
        padding-left: 20%;
      }
    }

    &:nth-child(4) {
      width: 19.91%;
    }

    .emailSignupBtn {
      margin-bottom: 30px;
    }

    .socialMedia {
      margin-top: 30px;

      li {
        display: inline-block;
        margin-right: 7px;

        a {
          color: $navy;
          display: block;
          text-align: center;
          font-size: 1.5em;

          .circle {
            height: 50px;
            width: 50px;
            border: 2px solid $navy;
            border-radius: 50%;
            transition: 0.2s;

            i {
              padding-top: 10px;
            }
          }

          &:hover {
            .circle {
              background-color: $navy;
              color: #fff;
              border: 2px solid $black;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .copyright {
      clear: both;
      margin-top: 50px;
      display: block;

      p {
        line-height: 2em;
        font-size: 1.1em;
      }

      a {
        color: black;
      }
    }
  }

  .footerCallout {
    position: absolute;
    color: $navy;
    margin-top: 79px;

    h5 {
      margin-bottom: 0;
      font-size: 1.7em;
      padding: 15px 30px 15px 0;
      border-top: 2px solid $navy;
      border-bottom: 2px solid $navy;
      float: left;
    }

    .circle {
      float: left;
      border: 3px solid $navy;
      height: 68px;
      width: 68px;
      border-radius: 50%;
      text-align: center;
      margin-right: 18px;
      margin-left: -13%;

      i {
        font-size: 2.5em;
        padding-top: 13px;
      }
    }
  }
}
/* ==========================================================================
Scroll to top
========================================================================== */
.scroll-to-top {
  width: 50px;
  height: 50px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 0;
  display: none;
  z-index: 2000;
  background-color: $darkestPaleGray;
  transition: transform 0.3s;
  overflow: hidden;

  &:after,
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 15px;
    background-color: #fff;
    height: 20px;
    width: 5px;
  }

  &:before {
    transform: rotate(-45deg);
    left: 28px;
    box-shadow: -8px 14px 0 8px darken($darkestPaleGray, 8%);
  }

  &:after {
    transform: rotate(45deg);
    right: 28px;
  }

  &:focus {
    outline: none;
  }
}
/* ==========================================================================
Product Listing
========================================================================== */
#mainListingContent {
  margin-top: 75px;

  .productFilter {
    font-family: "Lora", serif;

    h3 {
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 1.4em;
      letter-spacing: 1px;
      margin-bottom: 34px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 29px;

      li {
        padding: 4px 0;

        a {
          color: $mainFontColor;
          letter-spacing: 0.5px;
          font-size: 1.1em;

          &:hover {
            font-weight: 700;
          }
        }
      }
    }
  }

  .filterContainer {
    float: left;
    width: 25.24%;
    padding-right: 4.41%;
    border-right: 2px solid #6c6c6c;

    .sidebarFilter {
      border-top: 2px solid #6c6c6c;
    }

    .circle {
      border-radius: 50%;
      width: 21px;
      height: 21px;
      display: block;
      float: left;
      border: 1px solid $black;
      margin: 3px 5px 0 0;
    }

    button {
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 1.35em;
      margin: 16px 0 18px;
      padding: 0;
      background: none;
      border: none;
      cursor: pointer;
      display: block;
      text-align: left;
      width: 100%;
      outline: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        text-decoration: underline;
      }

      &:active {
        text-decoration: underline;
      }

      i {
        margin-right: 10px;
      }
    }

    .collapsed i {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
      margin-right: 10px;
    }

    .colorFilter {
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }

    .filterSidebarLink {
      font-family: "Montserrat", sans-serif;
      line-height: normal;
      margin-top: 50px;

      div {
        float: left;
      }

      p {
        max-width: 190px;
        padding-top: 4px;
      }

      a {
        color: $lighterNavy;
        font-weight: 500;
        font-size: 1.05em;

        span {
          padding-top: 5px;
          font-weight: 700;
          font-size: 1.1em;
          color: $blue;
          text-transform: uppercase;
          display: block;
        }
      }
    }

    .linkAccent {
      clear: both;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: 0.2s;
      color: $white;
      text-align: center;
      font-size: 1.25em;
      margin-right: 10px;
      background-image: linear-gradient(-180deg, $blue 25%, $navy);

      i {
        padding: 5px 0 0 4px;
      }

      &:hover {
        background-image: linear-gradient(-180deg, $green 25%, $navy);
      }
    }
  }

  #productResults {
    float: left;
    padding-left: 4.41%;
    width: 74.76%;
    font-family: "Montserrat", sans-serif;

    .resultsPaneSort {
      .breadcrumbs {
        text-transform: uppercase;
        color: $lighterNavy;

        a {
          color: $navy;
          font-size: 1.05em;
        }

        span {
          font-weight: 600;
        }
      }

      .itemsPerPage {
        a {
          color: $mainFontColor;
          text-decoration: none;

          span {
            font-weight: 600;
          }
        }

        form {
          float: left;

          label {
            cursor: pointer;
            margin-bottom: 0;
            padding: 10px;
            display: block;

            &:hover {
              background-color: #baafa5;
            }
          }

          span {
            font-weight: 600;
          }

          input[type="radio"] {
            position: fixed;
            opacity: 0;
          }
        }

        #itemsPerPage {
          position: absolute;
          z-index: 1;
          background-color: #faf8f4;
          border: 1px solid #bcbcbc;
        }
      }

      .itemsSortBy {
        p {
          display: inline;
        }

        a {
          color: $mainFontColor;
          text-decoration: none;
          font-weight: 600;
        }

        form {
          text-align: left;
          font-weight: 600;
          float: right;

          label {
            cursor: pointer;
            margin-bottom: 0;
            padding: 10px;
            display: block;

            &:hover {
              background-color: #baafa5;
            }
          }

          input[type="radio"] {
            position: fixed;
            opacity: 0;
          }
        }
      }

      #itemsSortBy {
        position: absolute;
        right: 15px;
        z-index: 1;
        background-color: #faf8f4;
        border: 1px solid #bcbcbc;
      }
    }
  }

  h2 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 2.2em;
    overflow: hidden;
    padding: 42px 0 54px;
    clear: both;
  }

  h2 > span {
    position: relative;
    display: inline-block;
  }

  h2 > span:after,
  h2 > span:before {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 3px solid;
    width: 591px;
    /* half of limiter*/
    margin: 0 40px;
  }

  h2 > span:before {
    right: 100%;
  }

  h2 > span:after {
    left: 100%;
  }

  #productListing {
    .product {
      background-color: $productListingBg;
      border: 1px solid #bcbcbc;
      width: 248px;
      margin-bottom: 46px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .spacerProduct {
      border: none;
      background-color: #fff;
    }

    .productTeaser {
      padding: 10px;

      .price {
        margin-top: 5px;
        font-weight: 600;
        font-size: 1.32em;
        line-height: normal;
      }

      .brand {
        font-weight: 700;
        color: #40886a;
        font-size: 1.1em;
        letter-spacing: 0.5px;
        line-height: normal;
      }

      .shortTitle {
        font-size: 1.32em;
        letter-spacing: 0.5px;
        line-height: normal;

        a {
          color: $blue;
          display: block;
        }
      }

      p {
        margin-bottom: 0;
      }

      table {
        margin: 10px 0;
        line-height: 22px;

        tr {
          td {
            &:first-child {
              color: #867668;
              text-transform: uppercase;
              border-right: 1px solid #000;
              padding-right: 10px;
            }

            &:last-child {
              padding-left: 10px;
            }

            &:nth-child(3) {
              text-align: right;
            }
          }
        }
      }

      .productDetailLinks {
        text-transform: uppercase;
      }

      .productDetailLinks {
        a {
          color: $mainFontColor;
          font-weight: 700;
          font-size: 0.9em;
          display: block;
        }
      }
    }

    .cartButtons {
      margin-top: auto;
      width: 100%;

      a {
        text-transform: uppercase;
        display: block;
        text-align: center;
        padding: 7px 0 5px;
        margin: 0 10px 10px;
        letter-spacing: 0.5px;
        text-decoration: none;

        &:first-child {
          background-color: $green;
          color: #fff;
        }

        &:last-child {
          background-color: $navy;
          color: #fff;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .pagination {
    text-transform: uppercase;
    text-align: right;
    margin-bottom: 60px;

    p {
      width: 100%;
    }

    i {
      color: #bfbfbf;
    }

    .fa-chevron-left {
      margin-right: 10px;
    }

    .fa-chevron-right {
      margin-left: 10px;
    }

    span {
      margin-left: 3%;
      display: inline;
    }
  }

  .mobileSidebarLinks {
    display: none;
  }
}
/* ==========================================================================
Free Shipping Callout
========================================================================== */
#freeShipCallout {
  margin-top: 6px;
  text-align: center;
  color: $navy;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.6em;
  letter-spacing: 1px;

  .freeShipMessage {
    background: url("../images/freeshipBg.jpg") no-repeat;
    background-size: cover;

    strong {
      font-weight: 600;
    }

    p {
      padding: 8px 0 7px;
      margin: 0 auto;

      span {
        height: 44px;
        width: 44px;
        border: 2px solid #00426b;
        border-radius: 50%;
        display: inline-block;
        margin-right: 12px;
      }
    }
  }
}
/* ==========================================================================
Product Detail Styles
========================================================================== */
#mainDetailContent {
  margin-top: 75px;
  font-family: 'Montserrat', sans-serif;

  .breadcrumbs {
    text-transform: uppercase;
    color: $lighterNavy;

    a {
      color: $navy;
      font-size: 1.05em;
    }
  }

  #productInfo {
    margin-top: 20px;

    .productImages {
      //width: 528px;
      width: 44%;
      position: relative;

      .tns-item {
        cursor: pointer;

        &:hover {
          img {
            border: 1px solid #000!important;
          }
        }
      }

      .mainProductImage {
        img {
          //width: 528px;
          border: 1px solid #c5c5c5;
        }

        .productImg1,
        .productImg2,
        .productImg3,
        .productImg4,
        .productImg5,
        .productImg6 {
          display: none;
        }
      }

      .slideViewContainer {
        width: 432px;
        margin: 24px auto 0;
        position: relative;
        outline: none;

        .tns-outer {
          position: relative;
          z-index: 1;
        }

        .productThumbContainer {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          z-index: 0;

          #customize-controls {
            list-style: none;
            padding: 0;
            font-size: 2.25em;
            width: 100%;
            height: 128px;

            i {
              color: #baafa5;
              cursor: pointer;
            }

            i:hover {
              color: #000;
            }

            .prev {
              margin-left: -48px;
            }

            .next {
              margin-right: -48px;
            }
          }
        }
      }

      #slideView {
        img {
          border: 1px solid #c5c5c5;
        }
      }
    }
  }

  #productCartSection {
    width: 50.18%;

    h2 {
      font-size: 2.1em;
      letter-spacing: 0.5px;
    }

    .skuLine {
      color: $lighterGreen;
      font-size: 1.1em;

      span {
        color: #000;
        padding: 0 5px;
      }
    }

    .priceDetails {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      padding: 10px 0;

      .mainPrice {
        border-right: 1px solid #000;
        padding: 15px 10% 0 0;

        p {
          &:first-child {
            font-weight: 600;
            font-size: 2.2em;
            letter-spacing: 1px;
          }

          &:last-child {
            font-size: 1.55em;
          }
        }
      }

      .volumePrice {
        padding: 10px 0 0 7%;

        p {
          &:first-child {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.4em;
            margin-bottom: 6px;
          }
        }

        table {
          font-size: 1.45em;
          border-collapse: collapse;
          width: 100%;
          table-layout: fixed;

          th {
            white-space: nowrap;

            &:nth-child(2) {
              padding-left: 7%;
            }

            &:nth-child(3) {
              text-align: right;
            }
          }

          td,
          th {
            font-weight: normal;
          }

          td:nth-child(2) {
            padding-left: 7%;
          }

          td:nth-child(3) {
            text-align: right;
          }

          thead {
            border-bottom: 1px solid #000;

            tr:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }

    .addToCart {
      margin-top: 20px;

      label {
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
      }

      input[type="text"] {
        float: left;
        border: 1px solid #bdbdbd;
        background-color: $productListingBg;
        height: 52px;
        text-align: center;
        width: 23.07%;
        margin-right: 5.57%;
        font-size: 1.3em;
        line-height: normal;
        padding: 0;
      }

      button {
        background-color: $green;
        border: none;
        border-radius: 0;
        font-size: 1.35em;
        letter-spacing: 0.5px;
        padding: 10px 0;
        width: 71.35%;

        &:hover {
          background-color: rgba(52,114,88, .9);
        }
      }

      .leftAddToCart {
        width: 62.62%;
        a{
          text-decoration:none;
        }
      }


      .stockIndicator {
        width: 32.72%;
        float: left;
        margin-top: 38px;
        margin-left: 4.65%;
        font-weight: 600;
        letter-spacing: 1px;

        p {
          color: $lighterGreen;
          margin-bottom: 0;

          &.noStock {
            color: $red;
          }
        }

        a {
          color: #000;
        }
      }
    }

    .customizePaperBtn {
      color: #005537;
      margin-top: 35px;

      div {
        &.circle {
          height: 60px;
          width: 15.91%;
          border: 2px solid #005537;
          border-radius: 50%;
          float: left;
          text-align: center;
          font-size: 2.5rem;
          margin-right: 3.97%;
          display: block;

          i {
            margin-top: 9px;
            padding-left: 7px;
            display: block;
          }
        }
      }

      p {
        padding: 5px 0;
        border-top: 1px solid #005537;
        border-bottom: 1px solid #005537;
        font-size: 1.25em;
        float: left;
        width: auto;
        display: inline;
        width: 80.10%;
        letter-spacing: 0.5px;
        line-height: normal;
        margin-bottom: 0;

        strong {
          color: #40886a;
        }
      }
    }

    .sampleSearchBtns {
      margin-top: 70px;

      a {
        color: #fff;
        display: inline-block;
        font-size: 1.1em;
        padding: 10px 0;
        text-align: center;
        float: left;

        &:first-child {
          background-color: $navy;
          width: 62.62%;
          margin-right: 4.65%;
        }

        &:last-child {
          background-color: $blue;
          width: 32.72%;
        }
      }
    }

    a {
      .paperFinderBtn {
        font-size: 1.15rem;
        color: $navy;
        line-height: normal;
        text-align: center;
        margin-top: 25px;
        display: block;
        border: 1px solid #c5c5c5;
        padding: 14px 0;
        background: url("../images/woodGrainBg.jpg") repeat-y;
        background-size: cover;

        p {
          margin: 0 10px 0 0;
        }

        strong {
          color: $blue;
          letter-spacing: 0.5px;
        }

        span {
          display: block;
          height: 23px;
          width: 23px;
          border-radius: 50%;
          color: #fff;
          background-image: linear-gradient(-180deg, #0078b2 25%, #00426b);

          i {
            font-size: 0.9rem;
            margin: 5px 0 0 2px;
            display: block;
          }
        }
      }
    }
  }

  #productQuickLinks {
    margin-top: 50px;
    border-top: 2px solid #baafa5;
    border-bottom: 2px solid #baafa5;
    width: 100%;
    align-items: center;

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0;
      font-weight: 600;
      width: 88%;
      margin: 0 auto;

      a {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #867668;
        font-size: 0.9em;
        padding: 15px 0;
        display: block;
        line-height: normal;
      }
    }
  }

  .relatedProducts {
    margin-top: 90px;

    h3 {
      text-align: center;
      font-weight: 400;
      font-size: 1.7em;
      overflow: hidden;
      margin-bottom: 45px;
    }

    h3 > span {
      position: relative;
      display: inline-block;
    }

    h3 > span:after,
    h3 > span:before {
      content: '';
      position: absolute;
      top: 50%;
      border-bottom: 3px solid;
      width: 591px;
      /* half of limiter*/
      margin: 0 15px;
    }

    h3 > span:before {
      right: 100%;
    }

    h3 > span:after {
      left: 100%;
    }

    .tns-outer {
      width: 1064px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }

    .relatedProductsCarouselWrapper {
      position: relative;
    }

    a {
      text-decoration: none;

      &:hover {
        .relatedProduct {
          border: 1px solid #000;
        }
      }
    }

    .relatedProduct {
      border: 1px solid #bcbcbc;
      background-color: #fbf9f7;

      img {
        width: 100%;
        height: auto;
      }

      p {
        padding: 0 15px;
        color: #000;
        margin-bottom: 0;

        &.productCardTitle {
          padding-top: 15px;
          font-weight: 500;
          font-size: 1.32rem;
          line-height: normal;
        }

        &:last-child {
          font-weight: 600;
          color: $lighterGreen;
          padding-bottom: 15px;
        }
      }
    }

    button {
      margin: 30px auto 0;
      display: block;
      border: none;
      background-color: #e6e3df;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 8px 40px 7px;
      line-height: normal;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .allRelatedProducts {
      display: none;
      width: 1064px;
      margin: 0 auto;

      .relatedProduct {
        max-width: 246px;
        margin-bottom: 10%;
      }

      img {
        max-width: 246px;
        height: auto;
      }
    }

    #relatedProduct-controls {
      list-style: none;
      position: absolute;
      width: 100%;
      height: 350px;
      padding: 0 5px;
      top: 0;
      z-index: 0;

      .next,
      .prev {
        color: #baafa5;
        cursor: pointer;
        font-size: 2.25em;

        &:hover {
          color: #000;
        }
      }
    }
  }

  #productDetails {
    margin: 90px 0 180px;

    h3 {
      text-align: center;
      font-weight: 400;
      font-size: 1.7em;
      overflow: hidden;
      margin-bottom: 45px;
    }

    h3 > span {
      position: relative;
      display: inline-block;
    }

    h3 > span:after,
    h3 > span:before {
      content: '';
      position: absolute;
      top: 50%;
      border-bottom: 3px solid;
      width: 591px;
      /* half of limiter*/
      margin: 0 15px;
    }

    h3 > span:before {
      right: 100%;
    }

    h3 > span:after {
      left: 100%;
    }

    .nav-tabs {
      border: none;

      li {
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1.5px;

        &.nav-item {
          width: 32.66%;
          margin-right: 1%;
          margin-bottom: -1px;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          color: #fff;
          line-height: normal;
        }
      }

      .nav-link {
        border-radius: 0;
        text-align: center;
        font-size: 1.6em;
        padding: 22px 0;
        border: none;

        &.active {
          padding-bottom: 32px;
        }
      }

      .descriptionTab a {
        background-color: $navy;
      }

      .specsTab a {
        background-color: #000;
      }

      .colorOptionsTab a {
        background-color: $green;
      }
    }

    .tab-content {
      .tab-pane {
        background-color: #f7f4ef;

        .col {
          padding: 3rem 3.3rem;

          p {
            &.pantone {
              font-weight: 500;
              letter-spacing: 1px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    #description-tab-panel {
      border-bottom: 7px solid $navy;
      border-top: 7px solid $navy;
    }

    #specs-tab-panel {
      border-bottom: 7px solid #000;
      border-top: 7px solid #000;
    }

    #colorOptions-tab-panel {
      border-bottom: 7px solid $green;
      border-top: 7px solid $green;
    }
  }

  #colorOptions-tab-panel,
  #mobileProductColors {
    .col {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    a {
      border: 1px solid #bcbcbc;
      margin-bottom: 32px;
      text-decoration: none;

      &:hover {
        border: 1px solid #000;
      }
    }

    .gridSpacer {
      width: 250px;
    }

    .colorOption {
      width: 248px;

      .productTitle {
        padding: 1rem;
      }

      img {
        width: 100%;
        height: auto;
      }

      p {
        margin-bottom: 0;

        &.productCardTitle {
          font-weight: 500;
          font-size: 1.32rem;
          line-height: normal;
          color: #000;
        }

        &:last-child {
          font-weight: 600;
          color: #40886a;
        }
      }
    }
  }

  #mobileProductColors .mobileAccordionPane {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 35px;

    .gridSpacer {
      width: 182px;
    }

    .colorOption {
      width: 180px;
    }

    a {
      display: inline-block;
    }
  }

  #mobileProductDetails {
    margin-bottom: 180px;

    button {
      width: 100%;
      border: none;
      color: #fff;
      font-size: 1.2em;
      padding: 18px 0;
      margin-bottom: 15px;
    }

    button[aria-expanded=true] {
      margin-bottom: 0;
    }

    .mobileAccordionPane {
      padding: 3rem 3.3rem;
      margin-bottom: 15px;

      p:last-child {
        margin-bottom: 0;
      }

      p.pantone {
        font-weight: 600;
      }
    }

    #mobileProductColors,
    #mobileProductDescription,
    #mobileProductSpecs {
      background-color: #f7f4ef;
    }

    .mobileDescription {
      background-color: $navy;
    }

    .mobileSpecs {
      background-color: #000;
    }

    .mobileColors {
      background-color: $green;
    }

    #mobileProductDescription {
      button {
        background-color: $navy;
      }
    }
  }
}
/* ==========================================================================
Custom Cutting Modal
========================================================================== */
#cuttingModal {
  font-family: 'Montserrat', serif;
  padding-right:0!important;

  .modal-dialog {
    max-width: 1200px;

    .modal-content {
      border: none;
      border-radius: 0;
      .modal-body{
        padding-left:6%;
        padding-right:6%;
      }

      .exitCustomCutting{
        position:absolute;
        right:0;
        top:0;
        background:none;
        border:none;
        font-size:2.2rem;
        cursor:pointer;
        padding-top:.4rem;
        padding-right:1rem;
      }

      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 2em;
        overflow: hidden;
        margin: 0px 0 61px;
        padding-top:6%;
      }

      h3 > span {
        position: relative;
        display: inline-block;
      }

      h3 > span:after,
      h3 > span:before {
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 3px solid;
        width: 591px;
        /* half of limiter*/
        margin: 0 15px;
      }

      h3 > span:before {
        right: 100%;
      }

      h3 > span:after {
        left: 100%;
      }

      .clickableLabel {
        cursor: pointer;
      }

      .nav-tabs {
        border: none;

        li {
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 1.5px;

          &.nav-item {
            width: 22%;
            margin-right: 8px;
            margin-bottom: -1px;
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            color: #fff;
            line-height: normal;
          }
        }

        .nav-link {
          border-radius: 0;
          text-align: center;
          font-size: 1.4em;
          padding: 12px 0;
          border: none;

          &.active {
            padding-bottom: 20px;
          }
        }

        .cuttingTab a {
          background-color: $navy;
        }

        .perforatingTab a {
          background-color: #000;
        }

        .scoringTab a {
          background-color: $green;
        }
      }

      .tab-content {
        .customCutAddToCart{
          padding-bottom:6%;
          form{
            display: flex;
            width:100%;
            flex-direction: row;
            justify-content: flex-end;
            div:nth-child(2){
              width:7.91%;
              margin-right:7%;
            }
            label{
              display:block;
            }
            button{
              background-color: #347258;
              border: none;
              border-radius: 0;
              font-size: 1.35em;
              letter-spacing: 0.5px;
              padding: 10px 50px;
              display:block;
              &:hover{
                text-decoration:underline;
                i{
                  text-decoration:underline;
                }
              }
            }

            input[type="text"]{
              border: 1px solid #bdbdbd;
              background-color: #faf8f4;
              height: 52px;
              text-align: center;
              width: 100%;
              font-size: 1.3em;
              line-height: normal;
              padding: 0;
            }

            .customPrice{
              margin-right:4%;
              p:first-child{
                text-align:right;
                margin-bottom:5px;
              }
              p:last-child{
                font-size:2.3em;
                font-weight:500;
                text-align:right;
                margin-bottom:0px;
              }
            }

            div:nth-child(3){
              align-self:flex-end;
            }


          }
        }
        .customCutReturns{
          color:$red;
          text-align:center;
          text-transform:uppercase;
          font-weight:500;
          font-size:1.3rem;
          letter-spacing:.5px;
          margin-top:50px;
        }
        .tab-pane {
          background-color: #f7f4ef;
          padding: 4.41%;

          h4 {
            font-weight: 400;
            border-bottom: 1px solid #000;
            font-size: 1.35em;
            margin-top: 50px;
          }


          .customizationOptionsWrapper, .customizationOptionsWriteWrapper {
            display: flex;
            justify-content: space-between;

            select{
              vertical-align:bottom;
            }

            #score1_direction{
              vertical-align:unset;
            }

            .customCutTextarea{
              width:57%;
              textarea{
                width:100%;
                vertical-align:top;
                padding:5px;
              }
              h4{
                border-bottom:none;
              }
            }

            .clearAllBtn{
              width: 35.5%;
              align-self:flex-end;
              button{
                width:100%;
                border:none;
                background-color:#baafa5;
                color:#000;
                padding:12px 0px;
                text-transform:uppercase;
                font-size:1.4rem;
                letter-spacing:1px;
                line-height:normal;
                cursor:pointer;
                &:hover{
                  text-decoration:underline;
                }
              }
            }

            .formLayoutWrapper {
              display: flex;
              justify-content: space-between;

              .cuttingFormLeft {
                width: 100%;
              }
            }

            .cuttingOptions {
              width: 57%;

              #customperf2{
                h4{
                  margin-bottom:14px;
                }
              }

              .standardFormatBtn {
                background-color: $navy;
                border-radius: 0;
                color: #fff;
                font-size: 1.3em;
                letter-spacing: 0.5px;
                padding: 10px 15%;
              }

              label[for='verticalCut1'],
              label[for='horizontalCut1'] {
                padding-top: 10px;
                margin-left: 5%;
              }

              label[for='verticalCut2'],
              label[for='horizontalCut2'] {
                margin-right: 5%;
              }

              .labelBtmAlign {
                vertical-align: bottom;
                margin-bottom: 0;
              }

              button {
                background-color: $green;
                border-radius: 0;
                color: #fff;
                font-size: 1.3em;
                letter-spacing: 1px;
                padding: 2px 0.55rem 2px 0.75rem;

                &:hover{
                  text-decoration:underline;
                  i{
                    text-decoration:underline;
                  }
                }

              }

              input[type='text'] {
                border: 1px solid #cdccca;
              }

              #customcutting1,
              #customcutting2,
              #customscoring1,
              #customperf1,
              #customperf2,
              #customperf3 {
                input[type='text'] {
                  width: 40px;
                  text-align: center;
                  padding: 4px 0;
                }
              }

              .cutRestrictions {
                h4 {
                  margin-bottom: 15px;
                }

                p {
                  color: $red;
                  margin-bottom: 10px;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }

              #customcutting2 {
                .formLayoutWrapper {
                  margin-top: 15px;
                }

                .cuttingFormRight {
                  align-self: flex-end;
                }
              }

              .currentSettings {
                h4 {
                  border-bottom: none;
                }
              }

              #customperf1{
                div{
                  vertical-align:bottom;
                  padding-bottom:10px;
                  &:last-child{
                    padding-bottom:0px;
                  }
                }
                label{
                  margin-bottom:0px;
                  &.clickableLabel{
                    margin-right:5%;
                  }
                }
              }

              #customperf2, #customperf3{
                .clickableLabel{
                  margin-right:5%;
                }
                .cuttingFormLeft{
                  div{
                    margin-top:6px;
                  }
                }
              }

              .currentSettingsContainer {
                background-color: #fff;
                border: 1px solid #c4bbb2;
                padding: 3%;

                table {
                  width: 100%;
                  border-bottom: 1px solid #000;

                  thead {
                    border-bottom: 1px solid #000;

                    th {
                      font-weight: normal;
                    }
                  }

                  td:nth-child(3),
                  th:nth-child(3) {
                    text-align: right;
                  }

                  td:nth-child(1),
                  td:nth-child(3) {
                    color: #40886a;
                  }

                  tr {
                    &:first-child {
                      td {
                        padding-top: 6px;
                      }
                    }

                    &:last-child {
                      td {
                        padding-bottom: 3px;
                      }
                    }
                    cursor: pointer;

                    &:hover {
                      background-color: $paleGray;

                      td {
                        color: $red;

                        i {
                          &:first-child {
                            display: inline-block;
                          }

                          &:last-child {
                            display: none;
                          }
                        }
                      }
                    }
                  }

                  td {
                    i {
                      &:first-child {
                        display: none;
                      }
                      margin-right: 2%;
                    }

                    &.cutLineIndicator {
                      hr{
                        border-top: 1px solid #000;
                      }
                    }

                    &.perfLineIndicator {
                      hr {
                        border-top: 1px dotted #000;
                      }
                    }

                    &.scoreLineIndicator {
                      hr {
                        border-top: 1px solid #d2d2d2;
                      }
                    }
                  }
                }

                .customCuttingTotal {
                  padding-top: 8px;
                  width: 100%;

                  p {
                    margin-bottom: 0;
                  }

                  span {
                    float: right;
                  }
                }
              }
            }
          }

          .customProductPreview {
            margin-top:10px;
            width: 342px;
            button{
              display:none;
            }
            .previewHeading{
              display:flex;
              justify-content:space-between;
              button{
                display:none;
              }
              p{
                font-weight:600;
                font-size:1.7rem;
                margin-bottom:0;
                line-height:normal;
              }
              a{
                color:#000;
                align-self:flex-end;
                &:hover{
                  text-decoration:underline;
                }
              }
            }
            .previewBox{
              background-color:#fff;
              border:1px solid #c4bbb2;
              font-size:1.05rem;
              position:relative;
              margin-top:35px;
              &.mobilePreviewBox{
                display:none;
              }
              .previewWidth{
                text-align:center;
                margin-top:-25px;
              }
              .previewHeight{
                transform: rotate(-90deg);
                -moz-transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                -o-transform: rotate(-90deg);
                position:absolute;
                left:0;
                text-align:center;
                margin-left:-30px;
              }
              .vText {
                transform: rotate(-90deg);
                -moz-transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                -o-transform: rotate(-90deg);
                width: 30px;
                text-align: center;
              }
              .previewIntegerAbs {
                position: absolute;
                float: left;
                text-align: center;
              }
            }
          }
          .customProductPreviewZoom{
            display:none;
            width:100%;
            .returnCutOptions{
              border:none;
              background-color:$navy;
              color:#fff;
              font-size:1.3em;
              padding:10px 8%;
              margin-bottom:50px;
              cursor:pointer;
              &:hover{
                text-decoration:underline;
                i{
                  text-decoration:underline;
                }
              }
            }
            .previewBox{
              background-color: #fff;
              border: 1px solid #c4bbb2;
              font-size: 1.05rem;
              position: relative;
              margin:0 auto;
            }

            .previewWidth{
              text-align:center;
              margin-top:-25px;
            }

            .previewHeight{
              transform: rotate(-90deg);
              -moz-transform: rotate(-90deg);
              -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              -o-transform: rotate(-90deg);
              position:absolute;
              left:0;
              text-align:center;
              margin-left:-30px;
            }
            .vText {
              transform: rotate(-90deg);
              -moz-transform: rotate(-90deg);
              -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              -o-transform: rotate(-90deg);
              width: 30px;
              text-align: center;
            }
            .previewIntegerAbs {
              position: absolute;
              float: left;
              text-align: center;
            }

          }
        }

        .standardFormats{
          display:none;
          .returnCutOptions{
            border: none;
            background-color: #00426b;
            color: #fff;
            font-size: 1.3em;
            padding: 10px 8%;
            margin-bottom: 50px;
            cursor: pointer;
            &:hover{
              text-decoration:underline;
              i{
                text-decoration:underline;
              }
            }
          }
          .standardFormatsWrapper{
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
          }
          .standardFormat{
            width:48%;
            display:flex;
            margin-bottom:50px;
            &:hover{
              background-color:#E1DEDA;
            }
          }

          .formatPreview{
            background-color:#fff;
            border: 1px solid #c4bbb2;
            flex-shrink:0;
          }
          .formatDetails{
            margin-left:1rem;
            align-self:center;
            .title{
              font-weight:400;
              font-size:1.3rem;
              margin-bottom:5px;
              text-transform:uppercase;
            }

            button {
              background-color:$green;
              border:none;
              padding:10px 20px;
              color:#fff;
              cursor:pointer;
              &:hover{
                text-decoration:underline;
                i{
                  text-decoration:underline;
                }
              }
            }
          }
        }

        #cuttingTabPanel {
          border-bottom: 7px solid $navy;
          border-top: 7px solid $navy;
        }

        #perforatingTabPanel {
          border-bottom: 7px solid #000;
          border-top: 7px solid #000;
        }

        #scoringTabPanel {
          border-bottom: 7px solid $green;
          border-top: 7px solid $green;
        }
      }
    }
  }
}

.mz-zoom-window {
  max-width:528px;
  max-height:528px;
}
